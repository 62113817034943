import React from 'react';
import logo from './logo.svg';
import './App.css';
import NavbarMain from './components/NavbarMain';
import HomeHeader from './components/HomeHeader';
import HomePackages from './components/HomePackages';

function App() {
  return (
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
    <div className='pb-5'>
      <NavbarMain />
      < HomeHeader />
      < HomePackages />
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#0099ff" fillOpacity={1} d="M0,160L48,138.7C96,117,192,75,288,58.7C384,43,480,53,576,74.7C672,96,768,128,864,165.3C960,203,1056,245,1152,250.7C1248,256,1344,224,1392,208L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z" /></svg>
      <section id="products" className='container-fluid bg-main'>
          <div className='row'>
              <div className='col-md-12 text-center mb-5' id="#prices-products">
                <h3 className='text-white'>PRODUK</h3>
              </div>
          </div>
          <div className='row'>
            <div className='col-md-4 mb-5'>
              <div className='container bg-light rounded shadow-lg py-5 font-lato'>
                <div className='card-header bg-main text-light rounded p-3'>
                  <h3 className='text-center'>Small Car</h3>
                </div>
                <div className='card-body text-center pt-3'>
                    <li className='fs-4 lh-lg'>Exterior</li>
                    <p className='text-muted  fs-5'>Poles exterior</p>
                </div>
                <div className='card-footer pt-3'>
                  <p className='fs-1 text-center fw-bold text-primary'>Rp. 275.000</p>
                </div>
              </div>
              <div className='buttons-pesan row justify-content-center mt-mines-25'>
                <div className='btn btn-primary w-50 py-3 fs-4 fw-bold'>Order Now</div>
              </div>
            </div>

            <div className='col-md-4 mb-5'>
              <div className='container bg-light rounded shadow-lg py-5 font-lato'>
                <div className='card-header bg-main text-light rounded p-3'>
                  <h3 className='text-center'>Small Car</h3>
                </div>
                <div className='card-body text-center pt-3'>
                    <li className='fs-4 lh-lg'>Interior</li>
                    <p className='text-muted  fs-5'>Poles exterior</p>
                </div>
                <div className='card-footer pt-3'>
                  <p className='fs-1 text-center fw-bold text-primary'>Rp. 180.000</p>
                </div>
              </div>
              <div className='buttons-pesan row justify-content-center mt-mines-25'>
                <div className='btn btn-primary w-50 py-3 fs-4 fw-bold'>Order Now</div>
              </div>
            </div>

            <div className='col-md-4 mb-5'>
              <div className='container bg-light rounded shadow-lg py-5 font-lato'>
                <div className='card-header bg-main text-light rounded p-3'>
                  <h3 className='text-center'>Small Car</h3>
                </div>
                <div className='card-body text-center pt-3'>
                    <li className='fs-4 lh-lg'>Glass Polishing</li>
                    <p className='text-muted  fs-5'>Poles exterior</p>
                </div>
                <div className='card-footer pt-3'>
                  <p className='fs-1 text-center fw-bold text-primary'>Rp. 100.000</p>
                </div>
              </div>
              <div className='buttons-pesan row justify-content-center mt-mines-25'>
                <div className='btn btn-primary w-50 py-3 fs-4 fw-bold'>Order Now</div>
              </div>
            </div>

            <div className='col-md-4 mb-5'>
              <div className='container bg-light rounded shadow-lg py-5 font-lato'>
                <div className='card-header bg-main text-light rounded p-3'>
                  <h3 className='text-center'>Small Car</h3>
                </div>
                <div className='card-body text-center pt-3'>
                    <li className='fs-4 lh-lg'>Engine Bay</li>
                    <p className='text-muted  fs-5'>Poles exterior</p>
                </div>
                <div className='card-footer pt-3'>
                  <p className='fs-1 text-center fw-bold text-primary'>Rp. 125.000</p>
                </div>
              </div>
              <div className='buttons-pesan row justify-content-center mt-mines-25'>
                <div className='btn btn-primary w-50 py-3 fs-4 fw-bold'>Order Now</div>
              </div>
            </div>
          </div>
      </section>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#0099ff" fillOpacity={1} d="M0,160L48,138.7C96,117,192,75,288,58.7C384,43,480,53,576,74.7C672,96,768,128,864,165.3C960,203,1056,245,1152,250.7C1248,256,1344,224,1392,208L1440,192L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z" /></svg>
    </div>
  );
}

export default App;
