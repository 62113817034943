import React from 'react'

const HomeHeader = () => {
  return (
    <div>
        <header className="masthead bg-main bg-gradient">
            <div className="container position-relative">
            <div className="row justify-content-center py-5 pt-5 pt-100" >
                <div className="col-xl-6">
                <div className="text-center text-white">
                    {/* Page heading*/}
                    <h1 className="mb-5 display-1 fw-bold">R3 Team Polish</h1>
                    <h2 className="mb-5">Kami siap mempercantik mobil kesayangan anda, pesan kami sekarang juga.</h2>
                    {/* Signup form*/}
                    {/* * * * * * * * * * * * * * * **/}
                    {/* * * SB Forms Contact Form * **/}
                    {/* * * * * * * * * * * * * * * **/}
                    {/* This form is pre-integrated with SB Forms.*/}
                    {/* To make this form functional, sign up at*/}
                    {/* https://startbootstrap.com/solution/contact-forms*/}
                    {/* to get an API token!*/}
                    <form className="form-subscribe" id="contactForm" data-sb-form-api-token="API_TOKEN">
                    {/* Email address input*/}
                    <div className="row">
                        <div className="col">
                        <a className="btn btn-primary btn-lg w-75 fs-4" href='#packages' id="submitButton" type="button">Pesan Sekarang</a>
                        </div>
                    </div>
                    {/* Submit success message*/}
                    {/**/}
                    {/* This is what your users will see when the form*/}
                    {/* has successfully submitted*/}
                    {/* Submit error message*/}
                    {/**/}
                    {/* This is what your users will see when there is*/}
                    {/* an error submitting the form*/}
                    <div className="d-none" id="submitErrorMessage"><div className="text-center text-danger mb-3">Error sending message!</div></div>
                    </form>
                </div>
                </div>
            </div>
            </div>
        </header>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#0099ff" fillOpacity={1} d="M0,288L48,261.3C96,235,192,181,288,154.7C384,128,480,128,576,149.3C672,171,768,213,864,224C960,235,1056,213,1152,208C1248,203,1344,213,1392,218.7L1440,224L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z" /></svg>

    </div>
  )
}

export default HomeHeader