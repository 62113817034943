import React from 'react'

const HomePackages = () => {
  return (
    <div>
        <section id="packages" className='container-fluid my-5 pt-5'>
          <div className='row'>
              <div className='col-md-12 text-center' id="#prices-packages">
                <h3>HARGA PAKETAN</h3>
              </div>
          </div>
          <div className='row d-flex flex-row flex-nowrap wrapScroll py-5'>
            <div className='col-md-5 col-11'>
              <div className='container rounded shadow-lg py-5 font-lato'>
                <div className='card-header bg-main text-light rounded p-3'>
                  <h3 className='text-center'>Small Car</h3>
                </div>
                <div className='card-body text-center pt-3'>
                    <li className='fs-4 lh-lg'>Exterior</li>
                    <li className='fs-4 lh-lg'>Interior</li>
                    <li className='fs-4 lh-lg'>Glass Polishing</li>
                    <li className='fs-4 lh-lg'>Engine Bay</li>
                </div>
                <div className='card-footer pt-3'>
                  <p className='fs-1 text-center fw-bold text-primary'>Rp. 525.000</p>
                </div>
              </div>
              <div className='buttons-pesan row justify-content-center mt-mines-25'>
                <div className='btn btn-primary w-50 py-3 fs-4 fw-bold'>Order Now</div>
              </div>
            </div>
            <div className='col-md-5 col-11'>
              <div className='container rounded shadow-lg py-5'>
                <div className='card-header bg-main text-light rounded p-3'>
                  <h3 className='text-center'>Medium Car</h3>
                </div>
                <div className='card-body text-center pt-3'>
                    <li className='fs-4 lh-lg'>Exterior</li>
                    <li className='fs-4 lh-lg'>Interior</li>
                    <li className='fs-4 lh-lg'>Glass Polishing</li>
                    <li className='fs-4 lh-lg'>Engine Bay</li>
                </div>
                <div className='card-footer pt-3'>
                  <p className='fs-1 text-center fw-bold text-primary'>Rp. 575.000</p>
                </div>
              </div>
              <div className='buttons-pesan row justify-content-center mt-mines-25'>
                <div className='btn btn-primary w-50 py-3 fs-4 fw-bold'>Order Now</div>
              </div>
            </div>
            <div className='col-md-5 col-11'>
              <div className='container rounded shadow-lg py-5'>
                <div className='card-header bg-main text-light rounded p-3'>
                  <h3 className='text-center'>Large Car</h3>
                </div>
                <div className='card-body text-center pt-3'>
                    <li className='fs-4 lh-lg'>Exterior</li>
                    <li className='fs-4 lh-lg'>Interior</li>
                    <li className='fs-4 lh-lg'>Glass Polishing</li>
                    <li className='fs-4 lh-lg'>Engine Bay</li>
                </div>
                <div className='card-footer pt-3'>
                  <p className='fs-1 text-center fw-bold text-primary'>Rp. 600.000</p>
                </div>
              </div>
              <div className='buttons-pesan row justify-content-center mt-mines-25'>
                <div className='btn btn-primary w-50 py-3 fs-4 fw-bold'>Order Now</div>
              </div>
            </div>

            <div className='col-md-5 col-11'>
              <div className='container rounded shadow-lg py-5'>
                <div className='card-header bg-main text-light rounded p-3'>
                  <h3 className='text-center'>Extra Large Car</h3>
                </div>
                <div className='card-body text-center pt-3'>
                    <li className='fs-4 lh-lg'>Exterior</li>
                    <li className='fs-4 lh-lg'>Interior</li>
                    <li className='fs-4 lh-lg'>Glass Polishing</li>
                    <li className='fs-4 lh-lg'>Engine Bay</li>
                </div>
                <div className='card-footer pt-3'>
                  <p className='fs-1 text-center fw-bold text-primary'>Rp. 655.000</p>
                </div>
              </div>
              <div className='buttons-pesan row justify-content-center mt-mines-25'>
                <div className='btn btn-primary w-50 py-3 fs-4 fw-bold'>Order Now</div>
              </div>
            </div>

          </div>
        </section>
    </div>
  )
}

export default HomePackages