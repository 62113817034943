import React from 'react'

const NavbarMain = () => {
  return (
    <div className=' bg-light'>
        <nav className="navbar navbar-expand-lg">
            <div className="container">
                <a className="navbar-brand" href="/#">R3TeamPolish</a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon" />
                </button>
                <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                <div className="navbar-nav">
                    <a className="nav-link active" aria-current="page" href="/#">Beranda</a>
                    <a className="nav-link" href="/#packages">Paket</a>
                    <a className="nav-link" href="/#products">Product</a>
                    <a className="nav-link disabled">Galeri</a>
                </div>
                </div>
            </div>
        </nav>

    </div>
  )
}

export default NavbarMain